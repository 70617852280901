import {
  baiHeUploadCallInfo,
  CallInfoParams,
  checkCallDevice,
  checkDeviceCardIsBind,
  uploadAudioFile,
} from '@/api/call-device';
import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

export enum CallStatus {
  // 未注册
  Unregistered,
  // 空闲
  Idle,
  // 通话中
  InCall,
  /** 来电 */
  Incoming,
  // 去电
  Outbound,
}

class UgClient {
  isBind = false;
  isCheckBind = false;
  client: WebSocket | undefined;
  currentCallNumber: string = ''; // 被叫
  currentCallDirection: 'Incoming' | 'Outbound' | undefined;
  currentRecordFileName: string | undefined;
  currentStartTime: number | undefined;
  missed: boolean = false;
  lastHangupTime: number = 0;
  imei: string = '';
  iccid: string = '';
  sysInfo: any = {};
  callStatus: CallStatus = CallStatus.Unregistered;
  message: any;
  reg: number | undefined;
  signal: number | undefined;
  currentSim: 0 | 1 | undefined;
  callInfoParams: CallInfoParams | undefined;
  answerTime: string | undefined;
  selecSimResultLoading: boolean = false;
  isFirstOpenUgClient = true;
  isUploadAudio = false; // 是否上传录音

  bindCallNumber = '';
  constructor() {
    makeAutoObservable(this);
  }

  init() {
    const WSURL = 'ws://127.0.0.1:8055';
    this.client = new WebSocket(WSURL);
    // onopen
    this.client.addEventListener('open', (ev) => {
      window.electron.log(`ugClient onopen: ${JSON.stringify(ev)}`);
    });

    this.client.addEventListener('error', (ev) => {
      window.electron.log(`ugClient error: ${JSON.stringify(ev)}`);
      this.callStatus = CallStatus.Unregistered;
    });

    this.client.addEventListener('close', (ev) => {
      window.electron.log(`ugClient close: ${JSON.stringify(ev)}`);
      this.callStatus = CallStatus.Unregistered;
    });

    this.client.addEventListener('message', (ev) => {
      if (typeof ev.data == 'string') {
        const msg = JSON.parse(ev.data);
        this.handelMessage(msg);
      }
    });
    // 启动UG客户端
    window.electron.send('openUGClient');
  }

  /**
   * 检查绑定
   */
  async checkBind() {
    try {
      if (this.isFirstOpenUgClient) {
        this.isFirstOpenUgClient = false;
        this.init();
      }
      this.isCheckBind = true;
      if (!this.imei || this.imei === '') {
        if (!this.message) {
          this.message = message.info('正在连接服务...', 0);
        }
        setTimeout(() => {
          this.checkBind();
        }, 2000);
        return;
      }
      if (this.message) {
        message.destroy();
      }
      const res = await checkCallDevice({ DeviceCode: this.imei });
      this.isBind = res.data;
      if (!this.isBind) {
        message.error('当前设备异常,请联系管理员');
      }
      this.isCheckBind = false;
    } catch (error) {
      const err = error as Error;
      message.error(`检查绑定错误: ${err.message}`);
      window.electron.log(`ugClient绑定错误: ${err.message}`);
      this.isCheckBind = false;
    }
  }

  /**
   * 处理收到的消息
   * @param msg 收到的消息
   */
  async handelMessage(msg: any) {
    const Event = msg.Event;
    const response = msg.response;
    console.log(msg);
    switch (Event) {
      case 'SysInfo':
        this.imei = msg.IMEI;
        this.currentSim = msg.CurSim;
        this.iccid = msg.ICCID;
        this.checkCardIsBind();
        this.sysInfo = msg;
        break;
      case 'IMEI':
        this.imei = msg.imei;
        break;
      case 'ICCID':
        this.iccid = msg.iccid;
        this.checkCardIsBind();
        break;
      case 'Reg':
        this.reg = msg.result; // 传递合适的 reg 值
        if (
          this.signal !== 0 &&
          this.reg !== 0 &&
          this.callStatus === CallStatus.Unregistered
        ) {
          this.callStatus = CallStatus.Idle;
        }
        if (this.signal === 0 || this.reg === 0) {
          this.callStatus = CallStatus.Unregistered;
        }
        break;
      case 'Callin':
        this.playHeadSet();
        this.currentCallDirection = 'Incoming';
        this.currentCallNumber = msg.caller;
        this.callStatus = CallStatus.Incoming;
        this.startRecord();
        this.isUploadAudio = false;
        this.answerTime = undefined;
        break;
      case 'Answer':
        this.stopPlay();
        this.answerTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.callStatus = CallStatus.InCall;
        this.isUploadAudio = true;
        break;
      // 未接
      case 'Hangup':
        const currentTime = new Date().getTime();
        console.log(
          'currentTime - this.lastHangupTime',
          currentTime - this.lastHangupTime,
        );

        if (currentTime - this.lastHangupTime > 200) {
          // 500 毫秒
          this.lastHangupTime = currentTime;

          this.stopPlay();
          if (this.currentCallDirection === 'Incoming') {
            this.missed = true;
          }
          this.callStatus = CallStatus.Idle;

          this.stopRecord();
        }
        break;
      case 'Signal':
        this.signal = msg.percent;
        if (
          this.signal !== 0 &&
          this.reg !== 0 &&
          this.callStatus === CallStatus.Unregistered
        ) {
          this.callStatus = CallStatus.Idle;
        }
        if (this.signal === 0 || this.reg === 0) {
          this.callStatus = CallStatus.Unregistered;
        }
        break;
      default:
        break;
    }
    switch (response) {
      // 停止录音
      case 'StopRecordResult':
        if (this.currentRecordFileName && this.callInfoParams) {
          try {
            if (this.isUploadAudio) {
              message.loading('录音上传中...', 0);
              // 获取录音文件
              const res = window.electron.sendSync('getRecordFileStream', {
                fileName: this.currentRecordFileName,
              });
              if (res.status === 'error') {
                throw new Error(res.message);
              }
              const blob = new Blob([res.data.audio], { type: 'audio/wav' });
              const formData = new FormData();
              formData.append('file', blob, res.data.fileName);
              const response = await uploadAudioFile(formData);
              if (response && response.data?.fileUrl) {
                const url = response.data.fileUrl;
                this.callInfoParams.OssPath = url;
              } else {
                throw new Error(JSON.stringify(response));
              }
            }
            baiHeUploadCallInfo(this.callInfoParams).then((res) => {
              if (res.code === '000000') {
                message.success('记录上传成功');
              } else {
                message.error(res.message);
                window.electron.log(
                  `ugClient file upload: ${JSON.stringify(res.message)}`,
                );
              }
            });
            message.destroy();
          } catch (error) {
            const err = error as Error;
            window.electron.log(
              `ugClient file upload: ${JSON.stringify(error)}`,
            );
            message.error(`记录上传错误: ${err.message}`);
            message.destroy();
          }
        }
        break;
      case 'GetCurSimResult':
        this.currentSim = msg.result;
        break;
      default:
        break;
    }
  }
  /**
   * 拨号
   * @param phone 手机号码
   */
  makeCall(phone: string) {
    this.missed = false;
    this.currentCallNumber = phone;
    const msg = {
      cmd: 'MakeCall',
      phone: phone,
    };
    this.currentCallDirection = 'Outbound';
    this.startRecord();
    this.callStatus = CallStatus.Outbound;
    this.websocketSendMsg(msg);
    this.isUploadAudio = false;
    this.answerTime = undefined;
  }

  /**
   * 挂断
   */
  hangup() {
    const msg = {
      cmd: 'Hangup',
    };
    this.missed = false;
    this.callStatus = CallStatus.Idle;
    this.websocketSendMsg(msg);
    this.stopRecord();
  }

  /**
   * 接听
   */
  answer() {
    const msg = {
      cmd: 'Pickup',
    };
    this.missed = false;
    this.stopPlay();
    this.callStatus = CallStatus.InCall;
    this.websocketSendMsg(msg);
    this.answerTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.isUploadAudio = true;
  }
  /**
   * 拒接
   */
  reject() {
    const msg = {
      cmd: 'Reject',
    };
    this.missed = false;
    this.stopPlay();
    this.callStatus = CallStatus.Idle;
    this.websocketSendMsg(msg);
    this.stopRecord();
  }
  /**
   * 播放铃声
   信号强度：0
   */
  playHeadSet() {
    const msg = {
      cmd: 'PlayHeadset', //命令类型
      filename: 'ringtone.wav', //语音文件名
      repeat: 1,
    };
    this.websocketSendMsg(msg);
  }
  /**
   * 停止播放铃声
   */
  stopPlay() {
    const msg = {
      cmd: 'StopPlay',
    };
    this.websocketSendMsg(msg);
  }
  /**
   * 开始录音
   * @param filename 录音名字
   */
  startRecord() {
    this.currentRecordFileName = `${this.currentCallNumber}-${
      this.currentCallDirection
    }-${new Date().getTime()}.wav`;
    this.currentStartTime = new Date().getTime();
    const msg = {
      cmd: 'RecordFile',
      filename: this.currentRecordFileName,
    };

    this.websocketSendMsg(msg);
  }

  /**
   * 停止录音
   */
  stopRecord() {
    const msg = {
      cmd: 'StopRecord',
    };
    const startTime = moment(this.currentStartTime!).format(
      'YYYY/MM/DD HH:mm:ss',
    );
    const endTime = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');
    const params = {
      StartTime: startTime,
      AnswerTime: this.answerTime || '1970/01/01 00:00:00', // 接通时间
      OssPath: '',
      EndTime: endTime,
      CallNumber: '', // 主叫
      DescNumber: '', // 被叫
      Direction: '',
    };
    if (this.currentCallDirection === 'Incoming') {
      params.CallNumber = this.currentCallNumber;
      params.DescNumber = this.bindCallNumber;
      params.Direction = 'inbound';
    }
    if (this.currentCallDirection === 'Outbound') {
      params.DescNumber = this.currentCallNumber;
      params.CallNumber = this.bindCallNumber;
      params.Direction = 'outbound';
    }
    this.callInfoParams = params;
    this.websocketSendMsg(msg);
  }
  /**
   * 选择手机卡
   * @param simno 卡1 或者 卡2
   */
  selectSim(simno: 0 | 1 = 0) {
    const msg = { cmd: 'SelectSim', simno };
    this.reg = 0;
    this.signal = 0;
    this.currentSim = simno;
    this.bindCallNumber = '';
    this.iccid = '';
    this.callStatus = CallStatus.Unregistered;
    this.websocketSendMsg(msg);
  }
  getCurSim() {
    const msg = { cmd: 'GetCurSim' };
    this.websocketSendMsg(msg);
  }

  /**
   * 发送消息
   * @param msg 要发送的消息
   */
  websocketSendMsg(msg: {
    cmd: string;
    phone?: string;
    filename?: string;
    repeat?: number;
    simno?: 0 | 1;
  }) {
    if (this.client?.readyState === WebSocket.OPEN) {
      this.client.send(JSON.stringify(msg));
    }
  }

  async checkCardIsBind() {
    if (this.iccid === null || this.iccid === '' || this.iccid === undefined) {
      this.bindCallNumber = '';
      return false;
    }
    const res = await checkDeviceCardIsBind({ CardCode: this.iccid });
    this.bindCallNumber = res.data?.CallNumber;
    if (res.data) {
      return true;
    }
    return false;
  }
}
const ugClient = new UgClient();
export default ugClient;
