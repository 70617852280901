import ReactDOM from 'react-dom/client';
import App from './App';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

import 'antd/dist/antd.less';
import '@/assets/css/styles.base.css';
import '@/assets/css/theme.light.css';
import '@/assets/css/theme.dark.css';
import '@/assets/css/theme.pro-components.less';

import incomingMp3 from '@/assets/audio/incoming.mp3';

import { store } from './store';

import reportWebVitals from './reportWebVitals';
import { switchAudioOutputDevice, switchMicrophone } from './utils/audio';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const audioConfig = localStorage.getItem('audio_config');

if (audioConfig) {
  const { inputValue, outputValue } = JSON.parse(audioConfig);
  setTimeout(() => {
    switchAudioOutputDevice(outputValue);
    switchMicrophone(inputValue);
  }, 1000);
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
      <audio style={{ display: 'none' }} id='sip-audio' src=''>
        <track kind='captions' />
      </audio>
      <audio
        loop
        style={{ display: 'none' }}
        id='sip-audio-incoming'
        src={incomingMp3}>
        <track kind='captions' />
      </audio>
    </ConfigProvider>
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
